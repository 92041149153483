import { CalendarMonth } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useTheme
} from "@mui/material";
import {
  DateRange,
  PickersShortcutsItem
} from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { FC, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UbicoCard from "../../../../../../components/custom/Card";

import UbicoAutocomplete from "../../../../../../components/custom/autocomplete/Autocomplete";
import DateRangeFilter from "../../../../../../components/custom/dates/DateRangeFilter";
import { UbicoTextField } from "../../../../../../components/custom/textfields/TextField";
import {
  Account,
  CreditUsage as CreditUsageInterface,
  StatisticParams,
  UbicoSubscription
} from "../../../../../../constants/data-types";
import { RootState } from "../../../../../../redux/store";
import AccountService from "../../../../../../services/accountService";
import { SegmentStatsFrequencies } from "../../../../../overview/constants/segment-stats";
import {
  CreditUsageOptions,
  CreditUsageType,
  CreditUsageTypeLabels,
  MAX_TRIAL_CREDITS,
} from "../../../constants/account-billing";
import CreditPeriodTotalUsage from "./usage/CreditPeriodTotalUsage";
import CreditPeriodUsage from "./usage/CreditPeriodUsage";
import TrialCreditUsage from "./usage/TrialCreditUsage";

const defaultParams: StatisticParams & { usage_type: number } = {
  start_date: null,
  end_date: null,
  cumulative: false,
  frequency: SegmentStatsFrequencies.Weekly,
  usage_type: -1,
  segment: null
}

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  { label: "All Time", getValue: () => [null, null] }
]

const CreditUsage: FC = (): ReactElement => {
  const theme = useTheme();

  const account: Account = useSelector((state: RootState) => state.account);
  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );

  const [statisticsParams, setStatisticParams] = useState(null)

  const [loading, setLoading] = useState(true);
  const [filteredCreditUsage, setFilteredCreditUsage] = useState<
    CreditUsageInterface[]
  >([]);

  const [isCumulative, setIsCumulative] = useState(false);

  const isTrialCredits = subscription?.trial_credits_used < MAX_TRIAL_CREDITS || account.plan_limits.period_credits === 0;
  const planCredits =
    account?.plan_limits?.period_credits

  const onUsageTypeChange = (usageType: CreditUsageType) => {
    setStatisticParams({...statisticsParams, usage_type: usageType});
  };

  const onChangeFrequency = (frequency: SegmentStatsFrequencies) => {
    setStatisticParams({...statisticsParams, frequency: frequency});
  };

  const onCumulativeChange = (cumulative: boolean) => {
    setIsCumulative(cumulative);
    setStatisticParams({...statisticsParams, cumulative: cumulative});
  };

  const onDateRangeChange = (startDate: string, endDate: string, frequency: SegmentStatsFrequencies) => {
    setStatisticParams({
      ...statisticsParams,
      start_date: startDate,
      end_date: endDate,
      frequency: frequency,
    });
  };

  const getCreditUsage = async () => {
    setLoading(true);
    const { data, error } = await AccountService.getCreditUsage(
      statisticsParams?.start_date?.slice(0, 10),
      statisticsParams?.end_date?.slice(0, 10),
      statisticsParams?.usage_type,
      statisticsParams?.cumulative,
      statisticsParams?.frequency
    );

    if (data && !error) setFilteredCreditUsage(data);
    setLoading(false);
  };

  useEffect(() => {
    if(statisticsParams) getCreditUsage()
  }, [statisticsParams])

  useEffect(() => {
    if (subscription?.current_period_start, subscription?.current_period_end) {
      // Add Previous and Current billing period shortcuts
      const startDate = dayjs(subscription?.current_period_start * 1000)
      const endDate = dayjs(subscription?.current_period_end * 1000)
      if (!shortcutsItems?.map(d => d.label)?.includes("Current Period")) {
        shortcutsItems.unshift({ label: "Current Period", getValue: () => [startDate, endDate]})
      }
      if (!shortcutsItems?.map(d => d.label)?.includes("Previous Period")) {
        shortcutsItems.unshift({
          label: "Previous Period",
          getValue: () => {
            const daysDiff = endDate.diff(startDate, 'days')
            return [startDate.subtract(daysDiff, "days"), endDate.subtract(daysDiff, "days")];
          }
        });
      }
      setStatisticParams({
        ...defaultParams,
        start_date: startDate?.toISOString()?.slice(0, 10),
        end_date: endDate?.toISOString()?.slice(0, 10),
      })
    }
  }, [subscription.current_period_end, subscription.current_period_start])

  const usageTypeOptions = [-1, ...CreditUsageOptions];

  const subscriptionStartDate = dayjs(subscription?.current_period_start * 1000)?.toDate()?.toLocaleDateString()
  const subscriptionEndDate = dayjs(subscription?.current_period_end * 1000)?.toDate()?.toLocaleDateString()

  return (
    <Stack spacing={theme.spacing(2)}>
      {isTrialCredits && <TrialCreditUsage />}
      <UbicoCard
        cardTitle="Credit usage"
      >
        <Stack spacing={theme.spacing(2)}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="body2" fontWeight={'bolder'}>Current period usage</Typography>
            <Stack alignSelf={'center'} direction={"row"} spacing={theme.spacing(1)} alignItems={'center'} height={'100%'}>
              <CalendarMonth
                fontSize="small"
                sx={{ color: theme.palette.text.secondary }}
              />
              <Typography variant="caption" color={"text.secondary"}>
                {subscriptionStartDate || ''} - {subscriptionEndDate || ''}
              </Typography>
            </Stack>
          </Stack>
          {planCredits > 0 && <CreditPeriodTotalUsage />}
          <Divider/>
          <Typography variant="body2" fontWeight={'bolder'}>Usage history</Typography>
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            spacing={theme.spacing(2)}
          >
            <DateRangeFilter
              shortcutItems={shortcutsItems}
              startDate={statisticsParams?.start_date || null}
              endDate={statisticsParams?.end_date || null}
              frequency={statisticsParams?.frequency}
              onFrequencyChange={onChangeFrequency}
              onRangeChange={onDateRangeChange}
              rangeFieldWidth={200}
            />
            <UbicoAutocomplete
              options={usageTypeOptions}
              size="small"
              sx={{ minWidth: 200, fontSize: 13, margin: 0 }}
              disabled={loading}
              disableClearable
              defaultValue={-1}
              renderInput={(props) => (
                <UbicoTextField
                  fullWidth size="small"
                  {...props}
                  label="Usage type"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              renderOption={(props: any, option: CreditUsageType) => (
                <Box component={"li"} {...props} key={option}>
                  {option >= 0 ? CreditUsageTypeLabels[option] : "All"}
                </Box>
              )}
              getOptionLabel={(option: CreditUsageType) => {
                return option >= 0 ? CreditUsageTypeLabels[option] : "All"
              }}
              onChange={(_, usageType: CreditUsageType) =>
                onUsageTypeChange(usageType)
              }
            />
            <FormControlLabel
              label={<Typography variant="caption">Cumulative</Typography>}
              disabled={loading}
              labelPlacement="bottom"
              control={
                <Switch
                  sx={{ p: 0, borderRadius: 30 }}
                  size="small"
                  checked={isCumulative}
                  onChange={(_, checked) => onCumulativeChange(checked)}
                />
              }
            />
          </Stack>
          <CreditPeriodUsage
            creditUsage={filteredCreditUsage}
            loading={loading}
          />
        </Stack>
      </UbicoCard>
    </Stack>
  );
};

export default CreditUsage;
