import { AxiosService, UbicoAPIResponse } from "../api-provider";
import { API_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";

const getAttributes = async (
  workspace_id: number
): Promise<UbicoAPIResponse<any>> => {
  try {
    const attributes = await AxiosService.get(
      `${API_PATH}/workspaces/${workspace_id}/conditions/attributes/v7/`
    );
    return { error: false, data: attributes.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getOperators = async (
  workspace_id: number
): Promise<UbicoAPIResponse<any>> => {
  try {
    const operators = await AxiosService.get(
      `${API_PATH}/workspaces/${workspace_id}/conditions/operators/v5/`
    );
    return { error: false, data: operators.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const ConditionService = {
  getAttributes,
  getOperators,
};

export default ConditionService;
