import { Brightness4, DarkMode, LightMode, SettingsBrightness } from "@mui/icons-material";
import { ButtonGroup, IconButton, Popover, Tooltip, useTheme } from "@mui/material";
import { FC, ReactElement, useContext, useState } from "react";
import { ColorMode } from "../constants/color-mode";
import { ColorModeContext } from "../Providers";
import UbicoButton from "./custom/buttons/Button";

const ColorModeButton: FC = (): ReactElement => {
  const theme = useTheme()
  const { setMode, mode } = useContext(ColorModeContext);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [colorModeOpen, setColorModeOpen] = useState(false)

  const handleClickColorMode = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget);
    setColorModeOpen((prev) => !prev);
  };

  const changeColorMode = (mode: ColorMode) => {
    setMode(mode);
  };

  const selectedMode = mode as ColorMode

  return (
    <>
      <Tooltip
        title={"Manage color mode"}
      >
        <IconButton size="small" onClick={handleClickColorMode}>
          <Brightness4 fontSize="small" />
        </IconButton>
      </Tooltip>
      <Popover
        open={colorModeOpen}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => handleClickColorMode(null)}
        slotProps={{
          paper: {
            sx: { borderRadius: 2, padding: theme.spacing(2) }
          }
        }}
      >
        <ButtonGroup variant="outlined">
          <UbicoButton
            size="small"
            sx={{textTransform: 'initial'}}
            startIcon={<LightMode fontSize="small"/>}
            variant={selectedMode === ColorMode.Light ? 'contained' : 'outlined'}
            onClick={() => changeColorMode(ColorMode.Light)}
          >
            Light
          </UbicoButton>
          <UbicoButton
            size="small"
            sx={{textTransform: 'initial'}}
            startIcon={<SettingsBrightness fontSize="small"/>}
            variant={selectedMode === ColorMode.System ? 'contained' : 'outlined'}
            onClick={() => changeColorMode(ColorMode.System)}
          >
            System
          </UbicoButton>
          <UbicoButton
            size="small"
            sx={{textTransform: 'initial'}}
            startIcon={<DarkMode fontSize="small"/>}
            variant={selectedMode === ColorMode.Dark ? 'contained' : 'outlined'}
            onClick={() => changeColorMode(ColorMode.Dark)}
          >
            Dark
          </UbicoButton>
        </ButtonGroup>
      </Popover>
    </>
  )
}

export default ColorModeButton
