import {
  Autocomplete,
  AutocompleteProps,
  Tooltip,
  styled
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { UserRoles } from "../../../constants/user-roles";
import { RootState } from "../../../redux/store";
import { isUserAllowed } from "../../../utils/user-role-utils";

export interface UbicoAutocompleteProps
  extends AutocompleteProps<
    any,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  > {
  leastRole?: UserRoles;
  loadMorePages?(): void;
  hasMorePages?: boolean;
}

const StyledAutocomplete = styled(Autocomplete)(({theme}) => ({
  "&.Mui-disabled": {
    pointerEvents: "auto",
  },
  "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: theme.palette.background.paper,
      fontSize: "0.8rem",
      "& .MuiAutocomplete-popupIndicator": {
        color: theme.palette.text.secondary,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.divider,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.text.primary,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
    },
    "& .MuiAutocomplete-option": {
      fontSize: "0.8rem",
    },
}));

const UbicoAutocomplete: React.FC<UbicoAutocompleteProps> = ({
  loadMorePages,
  hasMorePages = false,
  leastRole = null,
  ...props
}) => {
  const minimizedStyles = {
    "& .MuiInputBase-root": {
      fontSize: "0.8rem",
    },
    "& .MuiOutlinedInput-root": {
      paddingRight: "30px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.8rem",
    },
    "& .MuiAutocomplete-listbox": {
      fontSize: "0.8rem",
      "& .MuiAutocomplete-option": {
        fontSize: "0.8rem",
        minHeight: "30px",
      },
    },
  };

  const { user_role } = useSelector((state: RootState) => state.profile);
  let permissionDenied = false;

  if (leastRole) {
    permissionDenied = !isUserAllowed(user_role, leastRole);
  }

  const disabled = permissionDenied || props.disabled;
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : props?.onClick,
  };

  return (
    <Tooltip
      title={permissionDenied ? "You do not have the required permission." : ""}
    >
      <StyledAutocomplete
        {...props}
        {...adjustedButtonProps}
        sx={{ ...minimizedStyles, ...props.sx }}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const listboxNode = event.currentTarget;
            if (
              hasMorePages &&
              loadMorePages &&
              Math.abs(listboxNode?.scrollHeight - (listboxNode?.scrollTop + listboxNode?.clientHeight)) <= 1
            ) {
              loadMorePages();
            }
          },
          sx: {fontSize: '0.8rem', maxHeight: 200}
        }}
      />
    </Tooltip>
  );
};

export default UbicoAutocomplete;
