import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Account } from "../../constants/data-types";

const initialState: Account = {
  id: -1,
  name: "",
  website: "",
  completed_account_setup: false,
  average_contract_value: null,
  goal: "",
  unsubscribe_across_workspaces: true,
  created_at: null,
  plan_limits: {
    max_nylas_emails: null,
    max_people: null,
    max_profiles: null,
    max_segments: null,
    max_workspaces: null,
    buying_signals_enabled: true,
    integrations_enabled: true,
    period_credits: 0,
    free_trial_credits: 0,
  },
  available_credits: 0,
  has_billing: false,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccount: (state, action: PayloadAction<Account>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.website = action.payload.website;
      state.completed_account_setup = action.payload.completed_account_setup;
      state.average_contract_value = action.payload.average_contract_value;
      state.goal = action.payload.goal;
      state.company_size = action.payload.company_size;
      state.unsubscribe_across_workspaces =
        action.payload.unsubscribe_across_workspaces;
      state.reference = action.payload.reference;
      state.reference_details = action.payload.reference_details;
      state.created_at = action.payload.created_at;
      state.plan_limits = action.payload.plan_limits;
      state.has_billing = action.payload.has_billing;
      state.available_credits = action.payload.available_credits;
      state.default_workspace = action.payload.default_workspace;
    },
  },
});

export const { setAccount } = accountSlice.actions;

export default accountSlice.reducer;
